import { useEffect } from 'react';

export function useClickOutside(callback, refs) {
  useEffect(() => {
    if (callback) {
      const listener = (e) => {
        if (
          !refs.some((ref) => ref.current && ref.current.contains(e.target))
        ) {
          callback();
        }
      };

      setTimeout(() => {
        window.addEventListener('click', listener);
        window.addEventListener('touchstart', listener);
      }, 0);
      return () => {
        window.removeEventListener('click', listener);
        window.removeEventListener('touchstart', listener);
      };
    }
    // Избегаем лишних перерендеров, сравнивая конкретные рефы, а не весь массив
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, ...refs]);
}
