import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import ym, { YMInitializer } from 'react-yandex-metrika';
import { yandexMetrikaId } from './config';

export function YandexMetrika() {
  const history = useHistory();

  useEffect(() => {
    if (!yandexMetrikaId) return;
    return history.listen(() => {
      if (ym) {
        ym('hit', window.location.href);
      }
    });
  }, [history]);

  if (yandexMetrikaId) {
    return (
      <YMInitializer
        accounts={[yandexMetrikaId]}
        options={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true
        }}
      />
    );
  } else {
    return null;
  }
}
