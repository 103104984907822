import { setContext } from 'apollo-link-context';

export function createSetAccessTokenContextLink(authStore) {
  return setContext((_, { headers }) => {
    const { authData } = authStore.state;
    const token = authData && authData.accessToken;
    if (token) {
      return {
        headers: {
          ...headers,
          Authorization: 'Bearer ' + token
        }
      };
    }
    return {};
  });
}
