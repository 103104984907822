/**
 * Оборачивает асинхронную функцию и следит за тем, чтобы она одновременно выполнялась только один раз
 */
export function singleton(func) {
  function worker(...args) {
    if (worker.promise) {
      return worker.promise;
    }

    worker.promise = func(...args);

    worker.promise.then(() => {
      worker.promise = null;
    });

    return worker.promise;
  }

  return worker;
}
