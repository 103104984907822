import { ApolloLink } from 'apollo-link';
import { switchMap } from 'rxjs/operators';
import { from } from 'rxjs';

export function createWaitForTokenRefreshLink(authStore) {
  return new ApolloLink((operation, forward) => {
    if (authStore.isRefreshingToken()) {
      return from(authStore.refreshToken()).pipe(
        switchMap(() => forward(operation))
      );
    } else {
      return forward(operation);
    }
  });
}
