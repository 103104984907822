export function getFullFileName(file) {
  if (!file) return null;
  return [file.name, file.file_extension].filter((x) => x).join('.');
}

export function splitFileName(name) {
  const matches = name.match(/^(.+?)(?:\.([^.]+))?$/);
  if (matches) {
    return [matches[1], matches[2]];
  }
  return [name, ''];
}
